<template>
  <div class="login-page">
    <div class="notice-box">
      <p class="warning-title">注意：</p>
      <p class="warning-content">禁止多登，多登有售后不负责</p>
      <p class="warning-content">请勿绑定任何个人信息</p>
      <p class="warning-content">私下绑定出现任何问题</p>
      <p class="warning-content">与本店无关</p>
    </div>

    <!-- 扫码功能按钮 -->
    <div class="buttons">
      <button @click="startScanning" class="scan-btn" v-if="!scanning">
        点击此处扫描二维码登录
      </button>
    </div>

    <!-- 当点击按钮并开始扫描后，展示摄像头扫码区域 -->
    <div class="scanner" v-if="scanning">
      <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
    </div>
    <!-- 添加加载动画 -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
      <p class="loading-text">正在登录，请稍候...</p>
    </div>
    <!-- 上传二维码图片按钮 -->
    <div class="buttons">
      <button @click="triggerFileUploadAndCloseCamera" class="upload-btn">
        点击此处上传二维码登录
      </button>
      <!-- 隐藏的文件上传输入框 -->
      <input
        type="file"
        ref="fileInput"
        accept="image/*"
        @change="onFileChange"
        style="display: none"
      />
    </div>

    <!-- 播放视频的按钮 -->
    <button @click="openVideoModal" class="play-video-btn">视频教程</button>

    <!-- 视频模态框 -->
<div v-if="showVideoModal" class="modal">
  <div class="modal-content">
    <span class="close-btn" @click="closeVideoModal">&times;</span>
    <video controls class="tutorial-video">
      <source src="@/assets/images/jc.mp4" type="video/mp4" />
      您的浏览器不支持 video 标签。
    </video>
  </div>
</div>

    <div class="timing-info">
      <p>
        卡密激活时间:
        <span :class="{ 'error-text': isError, 'success-text': !isError }">{{
          activationTime
        }}</span>
      </p>
      <p>卡密到期时间:{{ expireTime }}</p>
    </div>

    <button @click="openContactModalAndCloseCamera" class="contact-btn">
      联系客服
    </button>

    <!-- 显示扫码或上传结果 -->
    <p v-if="resultMessage" class="result-message">{{ resultMessage }}</p>

    <!-- 隐藏的 canvas 元素，用于绘制上传的图片 -->
    <canvas ref="canvas" style="display: none"></canvas>

    <!-- 联系客服模态弹窗 -->
    <div v-if="showContactModal" class="modal">
      <div class="modal-content">
        <span class="close-btn" @click="closeContactModal">&times;</span>
        <h2>联系客服</h2>
        <p>请扫描以下二维码联系客服：</p>
        <img
          src="@/assets/images/kf1.png"
          alt="客服二维码"
          class="qr-code-img"
        />
      </div>
    </div>
    <!-- 到期弹窗模态弹窗 -->

    <div v-if="expirationPopupModal" class="overlay">
      <div class="modal">
        <div class="modal-content">
          <h2>提示：链接已到期</h2>
          <p>售后裙：等待给出</p>
          <p>如果是刚刚购买的</p>
          <p>注意看下发货时间</p>
          <p>淘宝消息我们不看的</p>
          <p>任何问题加裙联系裙主</p>
          <button class="contact-btn">立刻购买</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsQR from "jsqr";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      resultMessage: null,
      scanning: false,
      showContactModal: false,
      expirationPopupModal: false,
      sign: null,
      activationTime: "",
      expireTime: "",
      isLoading: false,
      showVideoModal: false, // 控制视频模态框的显示
    };
  },
  mounted() {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    this.sign = urlParams.get("sign");
    axios
      .post("/api/sq_cx", {
        sign: this.sign,
      })
      .then((response) => {
        if (response.data.msg === "查询成功") {
          this.activationTime = response.data.首次时间;
          const expireDateStr = response.data.到期时间;
          this.expireTime = expireDateStr;
          // 将到期时间字符串转换为 Date 对象
          let expireDate = this.parseExpireDate(expireDateStr);
          const currentDate = new Date();
          if (expireDate < currentDate) {
            this.expirationPopupModal = true;
          }
        } else {
          this.activationTime = response.data.msg;
          this.isError = true;
        }
      })
      .catch((error) => {
        console.error("请求失败:", error);
        this.activationTime = "未查询到时间";
        this.isError = true;
      });
  },
  methods: {
    openVideoModal() {
        console.log('按钮被点击了');
      this.showVideoModal = true;
    },
    closeVideoModal() {
      this.showVideoModal = false;
    },
    startScanning() {
      this.scanning = true;
    },
    onDecode(decodedString) {
      this.scanning = false;
      this.isLoading = true;
      const urlObj = new URL(decodedString);
      // const redirectUri = urlObj.searchParams.get("redirect_uri");
      let id;
      id = urlObj.toString();
      // let scope;
      // if (redirectUri) {
      //   const redirectUrlObj = new URL(redirectUri);
      //   scope = redirectUrlObj.searchParams.get("scope");
      //   const decodedScope = decodeURIComponent(scope);
      //   redirectUrlObj.searchParams.set("scope", decodedScope);
      //   let urlString = urlObj.toString();
      //   urlString = urlString.replace(/([?&]redirect_uri=)[^&]+/, "");
      //   urlString += `&redirect_uri=` + redirectUrlObj.toString();
      //   id = urlString;
      // } else {
      //   scope = urlObj.searchParams.get("scope");
      //   const decodedScope = decodeURIComponent(scope);
      //   urlObj.searchParams.set("redirect_uri", decodedScope);
      //   id = urlObj.toString();
      // }
      console.log(id);
      id = btoa(id);
      axios
        .post("/api/sq_sm", {
          sign: this.sign,
          url: id,
        })
        .then((response) => {
          if (response.data.msg === "扫码成功") {
            this.activationTime = response.data.首次时间;
            this.expireTime = response.data.到期时间;
            this.isError = false;
          } else {
            this.activationTime = response.data.msg;
            this.isError = true;
          }
        })
        .catch((error) => {
          console.error(error);
          this.resultMessage = "请求失败, 请稍后再试";
        })
        .finally(() => {
          this.isLoading = false; // 请求完成后隐藏加载动画
        });
    },
    onInit(promise) {
      promise.catch((error) => {
        console.error("摄像头初始化失败:", error);
        this.resultMessage = "摄像头初始化失败: " + error.message;
        this.scanning = false;
      });
    },
    triggerFileUploadAndCloseCamera() {
      this.scanning = false;
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.decodeQRCodeFromImage(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    parseExpireDate(expireDateStr) {
      // 使用正则表达式提取年、月、日、时、分、秒
      const match = expireDateStr.match(
        /(\d+)年(\d+)月(\d+)日 (\d+)时(\d+)分(\d+)秒/
      );
      if (match) {
        const year = match[1];
        const month = match[2] - 1; // JavaScript 的月份从 0 开始
        const day = match[3];
        const hour = match[4];
        const minute = match[5];
        const second = match[6];

        // 创建新的 Date 对象
        return new Date(year, month, day, hour, minute, second);
      } else {
        throw new Error("无法解析到期时间");
      }
    },
    decodeQRCodeFromImage(imageData) {
      const img = new Image();
      img.src = imageData;
      img.onload = () => {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);

        try {
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, canvas.width, canvas.height);
          if (code) {
            this.onDecode(code.data);
          } else {
            console.warn(
              "解析二维码失败，尝试生成的二维码内容：",
              imageData.data
            );
            this.resultMessage = "无法解析二维码, 请检查二维码的清晰度或格式";
          }
        } catch (error) {
          console.error("获取图像数据失败:", error);
          this.resultMessage = "获取图像数据失败，请检查图像格式";
        }
      };
      img.onerror = () => {
        this.resultMessage = "图片加载失败，请重新选择文件";
      };
    },
    openContactModalAndCloseCamera() {
      this.scanning = false;
      this.showContactModal = true;
    },
    closeContactModal() {
      this.showContactModal = false;
    },
  },
};
</script>

<style scoped>
.login-page {
  width: 100%;
  height: calc(100vh - 40px); /* 确保内容不超出视图高度 */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial, sans-serif;
  overflow-y: auto; /* 添加垂直滚动条 */
  padding: 10px;
}

.notice-box {
  text-align: center;
  margin-bottom: 20px;
}

.warning-title {
  font-weight: bold;
  font-size: 24px;
  color: #d32f2f;
  margin-bottom: 10px;
}

.warning-content {
  font-size: 18px;
  color: #d32f2f;
  margin-bottom: 10px;
}

.scanner {
  margin: 20px 0;
}

.file-upload {
  margin: 20px 0;
}

button {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.scan-btn {
  background: linear-gradient(45deg, #4caf50, #81c784);
  color: white;
}

.scan-btn:hover {
  background: linear-gradient(45deg, #388e3c, #66bb6a);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.upload-btn {
  background: linear-gradient(45deg, #2196f3, #64b5f6);
  color: white;
}

.upload-btn:hover {
  background: linear-gradient(45deg, #1976d2, #42a5f5);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.contact-btn {
  background: linear-gradient(45deg, #f44336, #e57373);
  color: white;
  width: 30%;
}

.contact-btn:hover {
  background: linear-gradient(45deg, #d32f2f, #ef5350);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.instructions {
  font-size: 16px;
  color: gray;
  margin-bottom: 5px;
}

.timing-info {
  font-size: 18px;
  color: black;
  margin-bottom: 5px;
  padding: 2px 0;
}

.result-message {
  font-size: 18px;
  color: red;
  margin-top: 20px;
}
.success-text {
  color: black; /* 正常显示黑色 */
}

.error-text {
  color: red; /* 错误显示红色 */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto; /* 模态框内容过多时滚动 */
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}
.expiration-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}

.qr-code-img {
  width: 100%;
  height: auto;
  margin-top: 15px;
}
/* 加载动画的覆盖层样式 */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column; /* 使圆圈和文字垂直排列 */
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* 转圈圈的动画 */
.spinner {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

/* 加载提示文字的样式 */
.loading-text {
  margin-top: 10px;
  font-size: 16px;
  color: white;
  text-align: center;
}

/* 定义转圈动画 */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.play-video-btn {
  background: black;
  color: white;
  font-size: 16px;
  padding: 10px 20px; /* 让按钮有合适的内边距 */
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 10px 0;
  width: auto; /* 宽度自动根据内容调整 */
}

.play-video-btn:hover {
  background: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tutorial-video {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 15px;
}
</style>
